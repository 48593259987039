* {
  box-sizing: border-box;
}

.horizontalNavigation {
  padding-top: 12px;
  padding-bottom: 10px;
  margin-top: 50px;
  background-color: rgba(163, 110, 171, 0.539);
}

.react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
  display: none;
}

.react-horizontal-scrolling-menu--scroll-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
