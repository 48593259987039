.genreNavigationBox {
  padding-top: 20px;
  padding-bottom: 20px;
  margin-top: 0px;
  background-color: rgba(154, 118, 163, 0.539);
}

.shiny {
  position: relative;
  overflow: hidden;
}

.shiny::after {
  content: '';
  position: absolute;
  top: calc(var(--y, 0) * 1px - 100px);
  left: calc(var(--x, 0) * 1px - 100px);
  width: 200px;
  height: 200px;
  background: radial-gradient(rgba(255, 255, 255, 0.5), #3984ff00 80%);
  opacity: 0;
  transition: opacity 0.2s;
}

.shiny:hover::after {
  opacity: 0.4;
}
