$finalHeight: 250px;
$finalWidth: 10 * $finalHeight;
$scrollBarHeight: 1px;

::-webkit-scrollbar {
  width: $scrollBarHeight;
  height: $scrollBarHeight;
}

::-webkit-scrollbar-button {
  width: $scrollBarHeight;
  height: $scrollBarHeight;
}

body {
  background: #7b7979;
}

div {
  box-sizing: border-box;
}
