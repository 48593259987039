.wrapper {
  background-color: #5a3951;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-attachment: scroll;
  height: 100%;
  width: 100%;
  background-image: linear-gradient(19deg, #522d6e 0%, #e299cf 100%);
  position: fixed;
  padding: 0;
  margin: 0;
  top: 0;
  left: 0;
  overflow-y: scroll;
  overflow-x: hidden;
}

.content {
  width: 100%;
  height: auto;
  top: 0;
  left: 0;
}

.footer {
  width: 100%;
  height: auto;
  background-color: #ffffff;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.background {
  padding: 0;
  margin: 0;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  position: fixed;
  background-size: cover;
  background-attachment: fixed;
}
